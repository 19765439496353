
const UserLevelNormal = 0
const UserLevelAgent  = 1
const UserLevelBoos   = 2
const UserLevelChief  = 3

export const levelOption = [
    {level:UserLevelNormal,title:"会员"},
    {level:UserLevelAgent,title:"代理"},
    {level:UserLevelBoos,title:"老板"},
    {level:UserLevelChief,title:"总监"},
]

export function userLevel2Tag(level){
    let tagObj = levelOption.filter(item=>item.level === parseInt(level));
    if (tagObj.length === 0){
        return "异常"
    }
    return (tagObj.pop()).title
}