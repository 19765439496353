<template>
  <div @click="dialogVisible=true">
    <slot></slot>
    <el-dialog custom-class="custom" append-to-body title="注册邀请人" :show-close="false" :visible.sync="dialogVisible" width="20rem">
      <div>
        <div class="flex-def">
          <img :src="info.avatar | tomedia" style="width: 4rem;height: 4rem" alt="">
          <div style="margin-left: 1rem">
            <div>昵称：{{info.nickname}}</div>
            <div>手机：{{info.phone}}</div>
            <div v-if="info.chain_info">级别：{{userLevel2Tag(info.chain_info.level)}}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {userLevel2Tag} from "@/view/member/tools";

export default {
  name: "invite",
  props:{
    from_uid:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      info:{},
      dialogVisible:false,
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    userLevel2Tag(e){
      return userLevel2Tag(e)
    },
    load(){
      this.$api.addons.chain.findUser({id:this.from_uid}).then(res=>{
        this.info = res;
      })
    },
  }
}
</script>

<style scoped>

</style>