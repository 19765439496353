<template>
  <div @click="dialogVisible=true">
    <slot></slot>
    <el-dialog custom-class="custom" append-to-body title="提现记录" :show-close="false" :visible.sync="dialogVisible"
               width="50rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="user.nickname" label="昵称"></el-table-column>
        <el-table-column prop="user.phone" label="手机"></el-table-column>
        <el-table-column prop="status" :formatter="elStatus" label="状态"></el-table-column>
        <el-table-column prop="fee" label="提现金额"></el-table-column>
        <el-table-column prop="real_fee" label="实际到账"></el-table-column>
        <el-table-column prop="rate_fee" label="手续费"></el-table-column>
        <el-table-column prop="get_info_log" label="提现方式" show-overflow-tooltip>
          <template #default="s">
            <div>{{ parseObj(s.row.get_info_log).bank_name }}</div>
            <div>姓名：{{ parseObj(s.row.get_info_log).name }}</div>
            <div>账号：{{ parseObj(s.row.get_info_log).account }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="申请时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="fail_log" label="异常信息" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "cashLog",
  props: {
    uid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: [],
      dialogVisible: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    elStatus(item) {
      let str = "";
      switch (parseInt(item.status)) {
        case 0:
          str = "待打款";
          break;
        case 1:
          str = "已打款";
          break
        case 2:
          str = "已驳回";
          break;
      }
      return str;
    },
    parseObj(log) {
      if (!log) return {};
      return JSON.parse(log);
    },
    load() {
      this.$api.member.cash.search({uid: this.uid, page: -1}).then(res => {
        this.list = res.list;
      })
    },
  }
}
</script>

<style scoped>

</style>