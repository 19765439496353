<template>
  <div>
    <div @click="load">
      <slot></slot>
    </div>
    <el-dialog custom-class="custom" append-to-body title="佣金记录" :show-close="false" :visible.sync="dialogVisible"
               width="70rem">
      <el-table :data="list" border>
        <el-table-column prop="id" width="80" label="编号"></el-table-column>
        <el-table-column prop="log" label="记录" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee"  width="80"  label="金额"></el-table-column>
        <el-table-column prop="order_id"  width="100"  label="关联订单编号"></el-table-column>
        <el-table-column prop="goods_id"  width="100"  :formatter="e=>e.goods_id ? e.goods_id : ''" label="关联商品编号"></el-table-column>
        <el-table-column prop="type" :formatter="typeFormat" label="奖励类型"></el-table-column>
        <el-table-column prop="created_at" label="奖励时间"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "UserAwardLog",
  props: {
    uid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      dialogVisible: false,
    }
  },
  mounted() {
  },
  methods: {
    typeFormat(e) {
      let str = "";
      switch (parseInt(e.type)) {
        case 1:
          str = "加盟区直推奖励";
          break;
        case 2:
          str = "见单奖励";
          break;
        case 3:
          str = "老板、总监平级";
          break;
        case 4:
          str = "总监业绩奖励";
          break;
        case 5:
          str = "普通商品直推奖励";
          break;
        case 6:
          str = "区域代理模式直推奖励";
          break;
        case 7:
          str = "区域代理模式区域奖励";
          break;
        case 8:
          str = "区域代理模式团队奖励";
          break;
        case 9:
          str = "区域代理模式合伙人奖励";
          break;
        case 10:
          str = "知识付费讲师奖励";
          break;
      }
      return str;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$api.member.cash.awardLog({id: this.uid, page: this.page}).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.dialogVisible = true;
      })
    },
  }
}
</script>

<style scoped>

</style>