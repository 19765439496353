<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="12">
            <el-button-group>
              <el-button @click="searchGo" type="primary">搜索</el-button>
              <el-button @click="searchReset">重置</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="user.id" label="编号"></el-table-column>
        <el-table-column  prop="user.nickname" label="昵称" show-overflow-tooltip></el-table-column>
        <el-table-column  prop="user.phone" label="手机号"></el-table-column>
        <el-table-column  prop="balance" label="佣金余额"></el-table-column>
        <el-table-column  prop="help_fee" label="帮扶金"></el-table-column>
        <el-table-column  prop="user.from_uid" label="当前团队长">
          <template #default="s">
            <div v-if="s.row.head_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.head_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.head_user.nickname }}</span></div>
            </div>
            <div v-else>用户为其团队长</div>
          </template>
        </el-table-column>
        <el-table-column  prop="user.from_uid" label="第一个团队长">
          <template #default="s">
            <div v-if="s.row.first_head_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.first_head_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.first_head_user.nickname }}</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  prop="user.from_uid" label="所属总监">
          <template #default="s">
            <div v-if="s.row.chief_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.chief_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.chief_user.nickname }}</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="300" label="操作">
          <template #default="s">
            <div class="flex-def flex-zBetween">
              <invite v-if="s.row.from_uid > 0" :from_uid="s.row.from_uid"><el-button type="text">团队上级</el-button></invite>
              <registerInvite v-if="s.row.user.from_uid > 0" :from_uid="s.row.user.from_uid">
                <el-button type="text">注册邀请人</el-button>
              </registerInvite>
              <team :uid="s.row.uid"><el-button type="text">所属团队</el-button></team>
              <cashLog :uid="s.row.uid"><el-button type="text">提现记录</el-button></cashLog>
              <UserAwardLog :uid="s.row.uid"><el-button type="text">佣金记录</el-button></UserAwardLog>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import invite from "@/components/chain/invite";
import registerInvite from "@/components/chain/register-invite";
import team from "@/components/chain/team";
import cashLog from "@/components/chain/cashLog";
import UserAwardLog from "@/components/chain/UserAwardLog";
export default {
  name: "manage",
  components:{UserAwardLog, invite,registerInvite,team,cashLog},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
      }
      this.searchGo()
    },
    load() {
      this.$api.addons.chain.searchUser({
        page:this.page,
        level:2,
        nickname:this.searchForm.nickname,
        phone:this.searchForm.phone,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>